.gateContainer {
    min-height: 100vh;
    display: flex;
    background: #f2f2f2;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}
.gateCard {
    width: 100%;
    max-width: 20rem;
}
.gateLogo {
    text-align: center;
    margin-bottom: 2rem;
}
.gateLogo img {
    max-height: 4rem;
    max-width: 16rem;
}